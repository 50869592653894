import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LovedOne } from 'src/app/classes/lovedone';
import { SpecialDate } from 'src/app/classes/special-date';
import { faUserPlus, faUserCircle, faDoorOpen, faTrashAlt, faCalendarAlt, faEdit, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from 'src/app/services/user.service';
import * as moment from 'moment';


@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.scss']
})
export class CustomerDashboardComponent implements OnInit {

  testDate: Date;

  lovedOnes: LovedOne[];
  featuredLovedOne: LovedOne;
  featuredDate: SpecialDate;
  featuredDays: number;
  modalRef: BsModalRef;
  modalLovedOne: LovedOne;
  userId: string;
  /*
    Font Awesome Icons
  */
  faUserPlus = faUserPlus;
  faUserCircle = faUserCircle;
  faDoorOpen = faDoorOpen;
  faTrashAlt = faTrashAlt;
  faCalendarAlt = faCalendarAlt;
  faEdit = faEdit;
  faCheckSquare = faCheckSquare;

  constructor(private router: Router, private modalService: BsModalService, private userService: UserService) {
    this.testDate = new Date();
    this.lovedOnes = null;
    this.featuredLovedOne = null;
    this.featuredDate = null;
    this.featuredDays = null;
    this.modalLovedOne = new LovedOne();
    this.userId = this.userService.getUserFromLocalStorage();
    this.getExistingLovedOnes();
  }

  ngOnInit() {
  }

  getExistingLovedOnes() {
    this.userService.getExistingLovedOnesForUser(this.userId).subscribe(
      (res: LovedOne[]) => {
        this.lovedOnes = res;
        this.assignFeaturedLovedOne();
      },
      (err) => {
        console.log(err);
      }
    )
  }
  editLovedOne(template: TemplateRef<any>, lovedOne: LovedOne){
    if(lovedOne == null){
      console.log('launching modal with new data object')
      this.modalLovedOne = new LovedOne();
    }else {
      console.log('launching modal for pre-existing loved one')
      this.modalLovedOne = lovedOne;
    }
    this.modalRef = this.modalService.show(template);
  }

  myDetails() {
    this.router.navigate(['/profile/details']);
  }

  logout() {
    this.userService.clearLocalUserData();
    this.router.navigate(['']);
  }
  editSpecialDate(specialDate: SpecialDate){
    specialDate._isEditable = true;
  }

  stopEditingSpecialDate(specialDate: SpecialDate){
    specialDate._isEditable = false;
  }

  removeSpecialDate(specialDate: SpecialDate){
    if(specialDate.id){
      if(!this.modalLovedOne.destroyables){
        this.modalLovedOne.destroyables = new Array<string>();
      }
      this.modalLovedOne.destroyables.push(specialDate.id);
    }
    this.modalLovedOne.specialDates = this.modalLovedOne.specialDates.filter(d => d !== specialDate);
  }

  addSpecialDate(){
    if(!this.modalLovedOne.specialDates){
      this.modalLovedOne.specialDates = new Array<SpecialDate>();
    }
    this.modalLovedOne.specialDates.push(new SpecialDate());
  }

  saveLovedOne(){
    this.userService.createLovedOne(this.userId, this.modalLovedOne).subscribe(
      (res: LovedOne) => {
          if (this.modalLovedOne.id) {
            //this is an update
            this.modalLovedOne = res;
            this.modalRef.hide();
          }else {
            this.modalLovedOne = res;
            if(!this.lovedOnes){
              this.lovedOnes = new Array<LovedOne>();
            }
            this.lovedOnes.push(res);
            this.modalRef.hide();
          }
          //This call below is hack to fix the updating issue -_- for now
          this.getExistingLovedOnes();
      },
      (err) => console.log(err)
    )
  }

  assignFeaturedLovedOne(){
    let closestUpcomingDate: SpecialDate = null;
    let closestLovedOne: LovedOne = null;
    let smallestDays: number = null;
    if (this.lovedOnes) {
      this.lovedOnes.forEach(lo => {
        if (lo.specialDates) {
          lo.specialDates.forEach(ud => {
            ud.date = moment(ud.date).startOf('day').format('YYYY-MM-DD');
            // days until test date
            const daysTilThis = this.daysUntilNextAnniversary(ud.date);
            if (!closestUpcomingDate) {
              closestUpcomingDate = ud;
              closestLovedOne = lo;
              smallestDays = daysTilThis;
            } else{
              const daysTilPrevious = this.daysUntilNextAnniversary(closestUpcomingDate.date);
              if (daysTilThis < daysTilPrevious) {
                closestUpcomingDate = ud;
                closestLovedOne = lo;
                smallestDays = daysTilThis;
              }
            }
          });
        }
      });
    }
    this.featuredLovedOne = closestLovedOne;
    this.featuredDate = closestUpcomingDate;
    this.featuredDays = smallestDays;
  }

  daysUntilNextAnniversary(storedDate: any) {
    const storedMDate = moment(storedDate);
    const currentDate = moment().startOf('day');
    if (storedMDate.format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')) {
      return 0;
    }
    if (currentDate.year() === storedMDate.year()) {
      // same year
      const dateDifference = storedMDate.diff(currentDate, 'days');
      if (dateDifference < 0){
        // use next year
        const dateToUse = moment([currentDate.year() + 1, storedMDate.month(), storedMDate.date()]);
        return dateToUse.diff(currentDate, 'days');
      } else{
        return dateDifference;
      }
    } else if (currentDate.year() < storedMDate.year()) {
      // future year
      return  storedMDate.diff(currentDate, 'days');
    } else {
      // year is in the past
      let dateToUse = moment([currentDate.year(), storedMDate.month(), storedMDate.date()]);
      if (dateToUse.isSame(currentDate)) {
        return 0;
      }
      if (currentDate > dateToUse) {
        // check next year if its earlier in the same year
        dateToUse = moment([currentDate.year() + 1, storedMDate.month(), storedMDate.date()]);
      }
      return dateToUse.diff(currentDate, 'days');
    }
  }

  clearDestroyables(){
    this.modalLovedOne.destroyables = null;
  }

  reachOut(){
    this.router.navigate(['/profile/reach-out']);
  }
}
