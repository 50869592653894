import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './user/login/login.component';
import { FullscreenLayoutComponent } from './layouts/fullscreen-layout/fullscreen-layout.component';
import { SignupComponent } from './user/signup/signup.component';
import { CustomerPortalLayoutComponent } from './layouts/customer-portal-layout/customer-portal-layout.component';
import { AdminPortalLayoutComponent } from './layouts/admin-portal-layout/admin-portal-layout.component';
import { AdminDashboardComponent } from './admin-portal/admin-dashboard/admin-dashboard.component';
import { CustomerDashboardComponent } from './customer-portal/customer-dashboard/customer-dashboard.component';
import { CustomerDetailsComponent } from './customer-portal/customer-details/customer-details.component';
import { ReachOutComponent } from './customer-portal/reach-out/reach-out.component';


const routes: Routes = [
  {
    path: '', component: FullscreenLayoutComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'login', component: LoginComponent },
      { path: 'signup', component: SignupComponent },
    ]
  },
  {
    path: 'profile', component: CustomerPortalLayoutComponent,
    children: [
      { path: '', component: CustomerDashboardComponent },
      { path: 'details', component: CustomerDetailsComponent },
      { path: 'reach-out', component: ReachOutComponent }
    ]
  },
  {
    path: 'admin', component: AdminPortalLayoutComponent,
    children: [
      { path: '', component: AdminDashboardComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
