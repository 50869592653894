import { Injectable } from '@angular/core';
import { User } from '../classes/user';
import { HttpClient } from '@angular/common/http';
import { LovedOne } from '../classes/lovedone';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiUrl = 'https://q91qn9klei.execute-api.ap-southeast-2.amazonaws.com/prod'; 
  //apiUrl = 'http://localhost:3000';
  // add server url in config then slug here
  constructor(private http: HttpClient) { }

  createUserAccount(user: User) {
    return this.http.post(this.apiUrl + '/user/create', user, { responseType: 'text' });
  }

  storeUserInLocalStorage(userId: string){
    localStorage.setItem('userId', userId);
  }

  getUserFromLocalStorage() {
    return localStorage.getItem('userId');
  }

  clearLocalUserData() {
    localStorage.clear();
  }

  getBasicUserDetails(userId: string) {
    return this.http.get(this.apiUrl + '/user/basic/' + userId);
  }

  signIn(user: User) {
    return this.http.post(this.apiUrl + '/user/signin', user, { responseType: 'text' });
  }

  signInWithToken(token: string) {
    return this.http.get(this.apiUrl + '/user/magic-signin/' + token, { responseType: 'text' });
  }

  getFullUserDetails(userId: string) {
    return this.http.get(this.apiUrl + '/user/full/' + userId);
  }

  updateFullUserDetails(user: User, userId: string) {
    return this.http.post(this.apiUrl + '/user/update/' + userId, user);
  }

  getExistingLovedOnesForUser(userId: string) {
    return this.http.get(this.apiUrl + '/user/special-connections/' + userId);
  }

  createLovedOne(userId: string, lovedOne: LovedOne){
    return this.http.post(this.apiUrl + '/user/special-connection/' + userId, lovedOne);
  }

  sendReachOut(userId: string, user: User, message: string) {
    console.log(message)
    return this.http.post(this.apiUrl + '/user/send-reach-out/' + userId, { user, message });
  }
}

