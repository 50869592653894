import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


@NgModule({
  declarations: [AdminDashboardComponent],
  imports: [
    CommonModule,
    BsDatepickerModule.forRoot(),
  ]
})
export class AdminPortalModule { }
