import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/classes/user';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reach-out',
  templateUrl: './reach-out.component.html',
  styleUrls: ['./reach-out.component.scss']
})
export class ReachOutComponent implements OnInit {

  userId: string;
  user: User;
  message: string;
  reachOutMessage:string;
  constructor(private userService: UserService, private router: Router) { 
    this.user = new User();
  }

  ngOnInit() {
    this.userId = this.userService.getUserFromLocalStorage();
    this.getCustomerDetails();
  }

  getCustomerDetails(){
    this.userService.getFullUserDetails(this.userId).subscribe(
      (res: User) => {
        if(res == null){
          console.log("User not found")
        }else {
          this.user = res
        }
      },
      (err) => console.log(err)
    );
  }

  sendReachOut(){
    this.message = null;
    this.userService.sendReachOut(this.userId, this.user, this.reachOutMessage).subscribe(
      (res) => {
        this.message = "Message sent!"
      },
      (err) => {
        this.message = "There was an error sending the message. Please try again later"
      }
    );
  }

  backToDashboard(){
    this.router.navigate(['/profile']);
  }
}