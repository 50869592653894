import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { User, BasicUser } from 'src/app/classes/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  userId: string = null;
  userToken: string = null;
  user: User;
  basicUser: BasicUser = null;
  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute) {
    this.user = new User();
    this.route.queryParams.subscribe(params => {
      this.userToken = params['token'];
  });
  }

  ngOnInit() {
    if(this.userToken){
      this.userService.signInWithToken(this.userToken).subscribe(
        (res: any)=>{
          if(!res){
            console.log('User not found. Login failed');
          }else{
            console.log('User found '+ res);
            this.userService.storeUserInLocalStorage(res);
            this.router.navigate(['/profile']);
          }
        },
        (err)=>console.log(err)
        );
    }
    this.userId = this.userService.getUserFromLocalStorage();
    if(this.userId !== null){
      this.userService.getBasicUserDetails(this.userId).subscribe(
        (res: BasicUser) => {
          this.basicUser = res;
        },
        (err) => console.log(err)
      );
    }
  }
  freshSignin(){
    this.userService.clearLocalUserData();
    this.basicUser = null;
  }
  freshSignup(){
    this.userService.clearLocalUserData();
    this.router.navigate(['/signup']);
  }
  signIn(){
    this.userService.signIn(this.user).subscribe(
      (res)=>{
        if(!res){
          console.log('User not found. Login failed');
        }else{
          console.log('User found '+ res);
          this.userService.storeUserInLocalStorage(res);
          this.router.navigate(['/profile']);
        }
      },
      (err)=>console.log(err)
      );
  }

  signInExisting() {
    this.user.firstName = this.basicUser.firstName;
    this.user.email = this.basicUser.email;
    this.user.lastName = this.basicUser.lastName;
    this.userService.signIn(this.user).subscribe(
      (res)=>{
        if(!res){
          console.log('User not found. Login failed');
        }else{
          console.log('User found '+ res);
          this.userService.storeUserInLocalStorage(res);
          this.router.navigate(['/profile']);
        }
      },
      (err)=>console.log(err)
      );
  }
}
