import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullscreenLayoutComponent } from './fullscreen-layout/fullscreen-layout.component';
import { AppRoutingModule } from '../app-routing.module';
import { CustomerPortalLayoutComponent } from './customer-portal-layout/customer-portal-layout.component';
import { AdminPortalLayoutComponent } from './admin-portal-layout/admin-portal-layout.component';



@NgModule({
  declarations: [FullscreenLayoutComponent, CustomerPortalLayoutComponent, AdminPortalLayoutComponent],
  imports: [
    CommonModule,
    AppRoutingModule
  ]
})
export class LayoutsModule { }
