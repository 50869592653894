import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-portal-layout',
  templateUrl: './customer-portal-layout.component.html',
  styleUrls: ['./customer-portal-layout.component.scss']
})
export class CustomerPortalLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
