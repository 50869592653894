import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { User, BasicUser } from 'src/app/classes/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  userId: string = null;
  user: User;
  constructor(private userService: UserService, private router: Router) {
    this.user = new User();
  }

  ngOnInit() {
    this.userId = this.userService.getUserFromLocalStorage();
    if(this.userId !== null){
      this.userService.getBasicUserDetails(this.userId).subscribe(
        (res: BasicUser) => {
          this.router.navigate(['/login']);
        },
        (err) => console.log(err)
      );
    }
  }

  async createAccount() {
    this.userService.createUserAccount(this.user).subscribe(
      (res) => {
        if(res === null){
          alert('User could not be created at this time. If you already have an account try to Sign in instead')
        }else {
          this.userService.storeUserInLocalStorage(res);
          this.router.navigate(['/profile'])
        }
      },
      (err) => console.log(err)
    );
  }
}
