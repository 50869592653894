import { SpecialDate } from './special-date';

export class LovedOne {
    id: string;
    name: string;
    relation: string;
    bioYou: string;
    bioThem: string;
    reaso: string;
    favouritegemstone: string;
    metal: string;
    wishlist: string;
    specialDateIds?: string[];
    specialDates?: SpecialDate[] = new Array<SpecialDate>();
    destroyables?: string[];
}